.ui-timepicker-container {
  position: absolute;
  overflow: hidden;
  box-sizing: border-box;
}

.ui-timepicker {
  box-sizing: content-box;
  display: block;
  height: 205px;
  list-style: none outside none;
  margin: 0;
  padding: 0 1px;
  text-align: center;
}

.ui-timepicker-viewport {
  box-sizing: content-box;
  display: block;
  height: 205px;
  margin: 0;
  padding: 0;
  overflow: auto;
  overflow-x: hidden; /* IE */
}

.ui-timepicker-standard {
  /* overwrites .ui-widget */
  font-family: Verdana,Arial,sans-serif;
  font-size: 1.1em;
  /* overwrites .ui-widget-content */
  background-color: #FFF;
  border: 1px solid #AAA;
  color: #222;
  /* overwrites .ui-menu */
  margin: 0;
  padding: 2px;
}
.ui-timepicker-standard a {
  border: 1px solid transparent;
  color: #222;
  display: block;
  padding: 0.2em 0.4em;
  text-decoration: none;
}
.ui-timepicker-standard .ui-state-hover {
  /* overwrites .ui-state-hover */
  background-color: #DADADA;
  border: 1px solid #999;
  font-weight: normal;
  color: #212121;
}
.ui-timepicker-standard .ui-menu-item {
  /* overwrites .ui-menu and .ui-menu-item */
  /*clear: left;
  float: left;*/
  margin: 0;
  padding: 0;
}

.ui-timepicker-corners,
.ui-timepicker-corners .ui-corner-all {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

.ui-timepicker-hidden {
  /* overwrites .ui-helper-hidden */
  display: none;
}

.ui-timepicker-no-scrollbar .ui-timepicker {
  border: none;
}


@import "daterangepicker";
@import "timepicker.scss";
@import "toastr";


@import "base.scss";
@import "components.scss";
@tailwind utilities;
@tailwind variants;
@tailwind screens;
@tailwind apply;


@layer variants {

}



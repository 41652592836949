@tailwind components;
@layer components {
    .form-field {
        line-height: 1.5rem;
        box-sizing: border-box;
    }

    .avatar {
        @apply flex justify-center rounded-full text-grey-lt bg-grey-xlt items-center w-24 h-24 text-2xl overflow-hidden
    }

    svg.avatar {
        overflow: hidden !important;

    }

    .avatar-lg {
        @apply w-32 h-32 text-4xl
    }

    .avatar-sm {
        @apply w-16 h-16 text-2xl
    }

    button.icon > * {
        display: inline-block
    }

    button.icon > svg {
        @apply px-4
    }

    button.primary {
        @apply inline-block px-6 py-2.5 bg-purple-md text-white font-medium leading-tight uppercase rounded shadow-md hover:bg-purple-lt hover:shadow-lg focus:bg-purple-md focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-xlt active:shadow-lg transition duration-150 ease-in-out flex-1;
    }
    button.secondary {
        @apply inline-block px-6 py-2.5 bg-white border-2 border-purple-md text-grey-md font-medium leading-tight uppercase rounded shadow-md hover:bg-purple-lt hover:shadow-lg focus:bg-purple-md focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-xlt active:shadow-lg transition duration-150 ease-in-out flex-1;
    }

    button.primary-green {
        @apply inline-block px-6 py-2.5 bg-green-md text-white font-medium leading-tight uppercase rounded shadow-md hover:bg-green-lt hover:shadow-lg focus:bg-green-md focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-lt active:shadow-lg transition duration-150 ease-in-out flex-1;
    }

    button.secondary-green {
        @apply inline-block px-6 py-2.5 bg-white border-2 border-green-md text-grey-md font-medium leading-tight uppercase rounded shadow-md hover:bg-green-lt hover:shadow-lg focus:bg-green-md focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-lt active:shadow-lg transition duration-150 ease-in-out flex-1;
    }
    button.primary-reverse {
        @apply inline-block px-6 py-2.5 bg-gray-100 text-purple-md font-medium leading-tight uppercase rounded shadow-md hover:bg-gray-50 hover:shadow-lg focus:bg-gray-200 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-50 active:shadow-lg transition duration-150 ease-in-out flex-1;
    }

    button.small, input[type='submit'].small{ @apply text-sm px-4 py-1.5; }
    button.xsmall, input[type='submit'].xsmall{ @apply text-xs px-2 py-1.5; }
    table.simple thead tr th {  @apply sticky top-0 bg-white p-4 border-b border-b-grey-xlt border-t border-t-grey-xlt text-grey-md font-semibold  }
    table.simple thead tr th:before, table.simple thead tr th:after {
        @apply absolute left-0 w-full;
        content: '';
    }
    table.simple thead tr th:before {@apply border-t border-t-grey-xlt top-[-1px];}
    table.simple thead tr th:after {@apply border-b border-b-grey-xlt bottom-[-1px];}

    table.simple tbody tr {
        @apply border-b border-b-grey-xlt
    }

    table.simple tbody tr td {
        @apply p-4
    }

    .collapse {
        visibility: collapse
    }

    .quagga {}
    .quagga > video { width: 100%;}
    .quagga > canvas{ position: absolute; top: 0; left:0; max-width: 100%; width: 100%;}

    /* toastr */

    #toast-container > div {
        opacity: 1;
        -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    }

    .toast {
        background-color: #030303;
        &.toast-success { @apply bg-green-dk; }
        &.toast-error { @apply bg-red-md; }
        &.toast-info {
            background-color: #2F96B4;
        }
        .toast-warning { @apply bg-orange-md; }
    }
    .dismiss-component {
        & .dismiss-component-slide{
            &.disabled {
                & > div {
                    background-color: #717171
                }
            }
        }
    }

}